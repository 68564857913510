// Step.js
import React, { useContext } from 'react';
import { FormContext } from '../App';
import { BeginRedemption } from './Forms/BeginRedemption';
import { CustomerDetails } from './Forms/CustomerDetails';
import { ThankYouPage } from './Forms/ThankYouPage';

function Step() {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <BeginRedemption />;
      break;
    case 1:
      stepContent = <CustomerDetails />;
      break;
    case 2:
      stepContent = <ThankYouPage />;
      break;
    default:
      break;
  }

  return stepContent;
}

export { Step };
