import React from 'react';

const ThankYouPage = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">
        Thank you! Your voucher has been redeemed.
      </h2>
      <p className="text-gray-700 mb-4">
        Your voucher is now being processed by our Customer Service Team.
      </p>
      <p className="text-gray-700 mb-4">
        You will be contacted by one of our representatives within the next 3 business days to
        schedule your cleaning.
      </p>
      <p className="text-gray-700">
        <strong>Note:</strong> Our Customer Service Team will perform your cleaning within 30 days
        of voucher redemption.
      </p>
    </div>
  );
};

export { ThankYouPage };
