import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { FormContext } from '../../App';
import * as yup from 'yup';
import axios from 'axios';
import { env } from '../../env.js';

const CustomerDetails = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(FormContext);

  const renderError = (fieldName) => (
    <ErrorMessage name={fieldName}>
      {(errorMessage) => (
        <p className="text-red-600 font-bold">
          {fieldName === 'firstName' && 'First Name is required'}
          {fieldName === 'lastName' && 'Last Name is required'}
          {fieldName === 'emailAddress' && 'Put a correct email ID'}
          {fieldName === 'phoneNumber' && 'Phone number must be 10 digits'}
          {fieldName === 'notes' && 'Notes is required'}
        </p>
      )}
    </ErrorMessage>
  );

  const ValidationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    emailAddress: yup.string().email(),
    phoneNumber: yup
      .string()
      .matches(/^\d{3}-?\d{3}-?\d{4}$/, 'Invalid Phone Number')
      .required(),
  });

  const handleSubmit = async (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
    try {
      // Simulate sending email to Service Admin
      await axios.post(env.VITE_POST_CUSTOMER_INFO_URL, data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        notes: '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form autoComplete="off" className="items-center min-h-screen bg-gray-100">
        <div className="max-w-2xl mx-auto p-6 bg-white rounded shadow">
          <div className="text-left mb-4">
            <p className="text-gray-700 mb-4">
              Thank you for starting the process of redeeming your voucher. You’re almost finished.
            </p>
            <p className="text-gray-700 mb-4">
              To ensure a smoother process for scheduling and completing your gutter service within
              the next 30 days, please provide the additional information below:
              <ul className="list-disc pl-8 mb-4">
                <li>Location on Your Home:</li>
                <li>
                  Type of Area (Rural/hilly/open field/urban/close proximity to street and/or
                  neighbors/many trees/no trees):
                </li>
                <li>Height on home (1 story vs. 2 story or 3 story home):</li>
              </ul>
            </p>
            <p className="text-gray-700 mb-4">
              Once a voucher is redeemed, please do not attempt to redeem any additional vouchers
              for at least 30 days. By following the steps below and completing this process, you
              are agreeing to have your gutter cleaning scheduled and completed within the next 30
              days. DO NOT click SUBMIT VOUCHER if you are unable to have this service completed
              within the next 30 days at your home. Thank you.
            </p>
            <p className="text-gray-700 mb-4">
              PLEASE follow these steps after completing the above information to complete your
              voucher redemption successfully:
            </p>
            <ol className="list-decimal pl-8 mb-4">
              <li className="text-gray-700 mb-2">SUBMIT by clicking 'SUBMIT VOUCHER' below.</li>
              <li className="text-gray-700 mb-2">
                You will be directed to a confirmation page and receive a confirmation email (If
                email address is provided) that your submission is complete.
              </li>
              <li className="text-gray-700 mb-2">
                Expect our Customer Care Team to contact you within 3 business days.
              </li>
              <li className="text-gray-700 mb-2">
                We will schedule your cleaning with our representatives for a visit to occur within
                30 DAYS of redeeming this voucher.
              </li>
            </ol>
            <p className="text-red-600 font-bold underline mb-4">
              PLEASE NOTE: Once you hit SUBMIT VOUCHER, this action cannot be undone. Please do not
              click SUBMIT VOUCHER unless you are able to have your gutter cleaning completed within
              the next 30 days. Thank you.
            </p>
          </div>

          <div className="flex flex-col items-center mb-4">
            <label className="font-medium text-gray-900">
              First Name<span className="text-red-500 font-bold"> *</span>
            </label>
            <Field
              name="firstName"
              className="rounded-md border-2 p-2 w-96"
              placeholder="Enter Your First Name"
              autoComplete="off"
            />
            {renderError('firstName')}
          </div>
          <div className="flex flex-col items-center mb-4">
            <label className="font-medium text-gray-900">
              Last Name<span className="text-red-500 font-bold"> *</span>
            </label>
            <Field
              name="lastName"
              className="rounded-md border-2 p-2 w-96"
              placeholder="Enter Your Last Name"
              autoComplete="off"
            />
            {renderError('lastName')}
          </div>
          <div className="flex flex-col items-center mb-4">
            <label className="font-medium text-gray-900">Email Address</label>
            <Field
              name="emailAddress"
              className="rounded-md border-2 p-2 w-96"
              placeholder="Please Enter Your Email Address"
              autoComplete="off"
            />
            <span className="text-xs text-gray-500 italic">Provide for smoother scheduling</span>
          </div>
          {renderError('emailAddress')}
          <div className="flex flex-col items-center mb-4">
            <label className="font-medium text-gray-900">
              Phone Number <span className="text-red-500 font-bold"> *</span>
            </label>
            <Field
              name="phoneNumber"
              className="rounded-md border-2 p-2 w-96"
              placeholder="Enter Your Phone Number"
              autoComplete="off"
            />
            {renderError('phoneNumber')}
          </div>
          <div className="flex flex-col items-center mb-4">
            <label className="font-medium text-gray-900">Notes</label>
            <Field
              as="textarea"
              name="notes"
              className="rounded-md border-2 p-2 w-96"
              placeholder="Enter Your Notes"
              autoComplete="off"
            />
          </div>
          <div className="flex justify-center">
            <button
              className="rounded-md bg-cyan-500 hover:bg-cyan-600 font-medium text-white my-2 p-2"
              type="submit"
            >
              SUBMIT VOUCHER
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export { CustomerDetails };
